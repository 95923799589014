export const ChainsInfo = {
  5: {
    NETWORK_NAME: "Goerli Testnet",
    CHAIN_NAME: "Ethereum",
    CURRENCY_SYMBOL: "ETH",
    NFT_MARKETPLACE_ADDRESS: "0x2a21d13776F7317b93ba74Ac7639ad39F4e2db8E",
    NFT_ADDRESS: "0xdC4643fc6A81247c75f5f434f9256A1de4C9aacb",
    WRAP_TOKEN: "0x50a5dB0124ebA53A5bb99f11730EB942De8D8338",
    IS_MAIN_NET: false,
    LOGO_URL:
      "https://upload.wikimedia.org/wikipedia/commons/0/05/Ethereum_logo_2014.svg",
    CHAIN_ID: "5",
    BLOCK_EXPLORER_URL: "https://goerli.etherscan.io/",
    RPC_PROVIDER_URL:
      "https://goerli.infura.io/v3/9c48d1f781404552b1a017d597f6bee1",
  },
  97: {
    NETWORK_NAME: "Binance Testnet",
    CHAIN_NAME: "binance",
    CURRENCY_SYMBOL: "BNB",
    NFT_MARKETPLACE_ADDRESS: "0x57cA8152959801BE48D7Df7b7058680CC3F1686A",
    NFT_ADDRESS: "0x890d7056337B8456550b3287725096815C3CCDD9",
    WRAP_TOKEN: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    IS_MAIN_NET: false,
    LOGO_URL:
      "https://upload.wikimedia.org/wikipedia/commons/0/05/Ethereum_logo_2014.svg",
    CHAIN_ID: "97",
    BLOCK_EXPLORER_URL: "https://testnet.bscscan.com",
    RPC_PROVIDER_URL: "https://data-seed-prebsc-1-s3.binance.org:8545",
  },
  80001: {
    NETWORK_NAME: "Polygon",
    CHAIN_NAME: "Polygon",
    CURRENCY_SYMBOL: "MATIC",
    // NFT_MARKETPLACE_ADDRESS: "0x86297da2C06D3469ae7fcD37ab09Ec1F64f6b402",
    // NFT_ADDRESS: "0xec74932C53c81b7f174eaa0D881Db50CE05a38f2",
    NFT_MARKETPLACE_ADDRESS: "0x9F63b7eb82C75277c0AD9cB9BbE692451CD2f62a",
    NFT_ADDRESS: "0xC5C5d08e62fa2a82048E5fbad9a3E38A34ef1Ad6",
    WRAP_TOKEN: "0x36339A0E5C372db012D419f0F920EdB3bE77338f",
    IS_MAIN_NET: false,
    LOGO_URL:
      "https://upload.wikimedia.org/wikipedia/commons/0/05/Ethereum_logo_2014.svg",
    CHAIN_ID: "80001",
    BLOCK_EXPLORER_URL: "https://mumbai.polygonscan.com/",
    RPC_PROVIDER_URL: "https://polygon-mumbai-bor.publicnode.com",
  },
  11155111: {
    NETWORK_NAME: "Sepolia Testnet",
    CHAIN_NAME: "EthereumSepolia",
    CURRENCY_SYMBOL: "ETH",
    NFT_MARKETPLACE_ADDRESS: "0xa527c9578Bd1EF73f0102ff9379Ab003F0026E32",
    NFT_ADDRESS: "0x8a6337a6eFDA1aADc72d0ab1bbc2c535d961E4dE",
    WRAP_TOKEN: "0x20935E173bff982458E245382f13b63c484BE701",
    IS_MAIN_NET: false,
    LOGO_URL:
      "https://upload.wikimedia.org/wikipedia/commons/0/05/Ethereum_logo_2014.svg",
    CHAIN_ID: "11155111",
    BLOCK_EXPLORER_URL: "https://sepolia.etherscan.io/",
    RPC_PROVIDER_URL: "https://sepolia.infura.io/v3/dc33d84612cc470db1924b37c4248c60",
  },
};
