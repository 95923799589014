import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDPHlh6xzfy0pe5Mh5UtUXB2XJAxpxRfZA",
  authDomain: "nfjlabs.firebaseapp.com",
  projectId: "nfjlabs",
  storageBucket: "nfjlabs.appspot.com",
  messagingSenderId: "318312623560",
  appId: "1:318312623560:web:ad60b2f88962eead78a4fd",
};
const app = initializeApp(firebaseConfig);
export default app;

// import { initializeApp } from "firebase/app";
// const firebaseConfig = {
//   apiKey: "AIzaSyC4HUOC5cj-26wa9aD5w1dUoa22YTYadBA",
//   authDomain: "nfjlabs-f3662.firebaseapp.com",
//   projectId: "nfjlabs-f3662",
//   storageBucket: "nfjlabs-f3662.appspot.com",
//   messagingSenderId: "688664659504",
//   appId: "1:688664659504:web:871aea9565a25d63358ff5",
//   measurementId: "G-ME02EGGH4P",
// };
// const app = initializeApp(firebaseConfig);
// export default app;
