import React from "react";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import HeroPrivacy from "../../../components/hero/HeroPrivacy";
import useDocumentTitle from "../../../components/useDocumentTitle";

const PrivacyPolicy = () => {
  useDocumentTitle("NFJ Labs - Privacy Policy");
  return (
    <div>
      <Header />
      <HeroPrivacy />
      <div className="privacy__page">
        <div className="container">
          <div className="box space-y-30 my-5" style={{ padding: "60px 30px" }}>
            <div className="space-y-20">
              <h3>What information do we collect?</h3>
              <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about Your privacy rights and how the law protects
                You.</p>
              <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this Privacy Policy. </p>
              <h3>Definitions</h3>
              <p>For the purposes of this Privacy Policy:</p>
              <ul>
                <li>
                  <p><strong>Account</strong> means a unique account created for You to access our Service or parts of
                    our Service.</p>
                </li>
                <li>
                  <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;,
                    &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to nfjlabs.</p>
                </li>
                <li>
                  <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any
                    other device by a website, containing the details of Your browsing history on that website among
                    its many uses.</p>
                </li>
                <li>
                  <p><strong>Country</strong> refers to: Maharashtra, India</p>
                </li>
                <li>
                  <p><strong>Device</strong> means any device that can access the Service such as a computer, a
                    cellphone or a digital tablet.</p>
                </li>
                <li>
                  <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                    individual.</p>
                </li>
                <li>
                  <p><strong>Service</strong> refers to the Website.</p>
                </li>
                <li>
                  <p><strong>Service Provider</strong> means any natural or legal person who processes the data on
                    behalf of the Company. It refers to third-party companies or individuals employed by the Company
                    to facilitate the Service, to provide
                    the Service on behalf of the Company, to perform services related to the Service or to assist
                    the Company in analyzing how the Service is used.</p>
                </li>
                <li>
                  <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use
                    of the Service or from the Service infrastructure itself (for example, the duration of a page
                    visit).</p>
                </li>
                <li>
                  <p><strong>Website</strong> refers to nfjlabs, accessible from <a
                    href="https://nfjlabs.io" rel="external nofollow noopener"
                    target="_blank">https://nfjlabs.io</a></p>
                </li>
                <li>
                  <p><strong>You</strong> means the individual accessing or using the Service, or the company, or
                    other legal entity on behalf of which such individual is accessing or using the Service, as
                    applicable.</p>
                </li>
              </ul>
              <h3>Collecting and Using Your Personal Data</h3>
              <p>While using Our Service, we do not ask any of our users to provide their personal information. As a part
                of security reasons and privacy to the financial data they are storing with us its our responsibility to
                keep it safe. The data entered by our users is completely safe and secure and also unidentical as we do
                not store any personal information of our users and is
                limited to :</p>
              <ul>
                <li>
                  <p>Email address</p>
                </li>
                <li>
                  <p>Username</p>
                </li>
                <li>
                  <p>country & currency</p>
                </li>
              </ul>
              <h3>Usage Data</h3>
              <p>Usage Data is collected automatically when using the Service.</p>
              <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
                the time spent on those pages,
                unique device identifiers and other diagnostic data.</p>
              <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
              <h3>Cookie Data</h3>
              <ul>
                <li>
                  <p><strong>Necessary / Essential Cookies</strong></p>
                  <p>Type: Session Cookies</p>
                  <p>Administered by: Us</p>
                  <p>Purpose: These Cookies are essential to provide You with services available through the Website
                    and to enable You to use some of its features. They help to authenticate users and prevent
                    fraudulent use of user accounts. Without
                    these Cookies, the services that You have asked for cannot be provided, and We only use these
                    Cookies to provide You with those services.</p>
                </li>
                <li>
                  <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                  <p>Type: Persistent Cookies</p>
                  <p>Administered by: Us</p>
                  <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                </li>
                <li>
                  <p><strong>Functionality Cookies</strong></p>
                  <p>Type: Persistent Cookies</p>
                  <p>Administered by: Us</p>
                  <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                    remembering your login details or language preference. The purpose of these Cookies is to
                    provide You with a more personal experience
                    and to avoid You having to re-enter your preferences every time You use the Website.</p>
                </li>
              </ul>
              <p>For more information about the cookies we use and your choices regarding cookies, please visit our
                Cookies Policy or the Cookies section of our Privacy Policy.</p>
              <h3>Children's Privacy</h3>
              <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
                identifiable information from anyone under the age of 13. If You are a parent or guardian and You are
                aware that Your child has provided Us
                with Personal Data, please contact Us. If We become aware that We have collected Personal Data from
                anyone under the age of 13 without verification of parental consent, We take steps to remove that
                information from Our servers.</p>
              <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
                consent from a parent, We may require Your parent's consent before We collect and use that information.
              </p>
              <h3>Links to Other Websites</h3>
              <p>We may never promote any unauthorized services on our platform as our platform will be used for financial
                management purpose and we do not want our users to face any kind of issues.</p>
              <h3>Changes to this Privacy Policy</h3>
              <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy Policy on this page.</p>
              <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
              <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.</p>
              <h3>Contact Us</h3>
              <p>If you have any questions about this Privacy Policy, You can contact us:</p>
              <ul>
                <li>By email: support@nfjlabs.io</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
