export const TransStatus = {
  mint: "Mint",
  place_bid: "Place Bid",
  purchase_nft: "Purchase Nft",
  put_on_sale: "Put On Sale",
  put_on_auction: "Put On Auction",
  remove_on_sale: "Remove On Sale",
  remove_on_auction: "Remove On Auction",
  transfer_to_high: "Transfer To High",
};
