import React from 'react';

function HeroQuestions() {
  return (
    <div>
      <div className="hero_questions bg_white">
        <div className="container">
          <div className="space-y-20">
            <h1 className="text-center">Frequently asked questions</h1>
            <p className="text-center">
              We have answered here some frequently asked questions
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroQuestions;
